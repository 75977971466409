/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResizeObserver = window.ResizeObserver || Polyfill;

// Ignore, polyfill.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).process = { env: { DEBUG: undefined } };

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

// Ignore, polyfill.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;

/**
 * In order to udpate to the latest Ionic version, we needed to migrate away from
 * IonSlides to use Swiper instead. Since Swiper uses Web Elements, we need to
 * register them here.
 */
import { register } from 'swiper/element/bundle';
register(); // https://ionicframework.com/docs/angular/slides
